import React from 'react';

import './TOSView.scss';
import PrivacyPolicyView from '../PrivacyPolicyView';

class FindUsView extends React.PureComponent {
  render() {
    return (
      <>
        <article className="TOSView">
          <div className="container">
            <section>
              <div>
                <h2 style={{ textAlign: 'center', marginBottom: 30 }}>
                  Terms of Service
                </h2>
              </div>
            </section>
            <section className="industry">
              <div>
                <p>
                  This Terms of Service explains what information may be
                  collected through the Select, Inc. (“Company”) website (“Site”), how
                  such information may be used and/or shared with others, how we
                  safeguard it and how you may access and control its use in
                  connection with our marketing communications and activities.
                  Please read this Terms of Service carefully, because by using
                  the Site you are acknowledging that you understand and agree
                  to the terms of this Terms of Service.
                </p>
                <h3>Use of site</h3>
              </div>
            </section>
            <section className="legal-content">
              <div>
                <p>
                  We authorize you to view and download the materials at this
                  Site only for your personal, non-commercial use.
                </p>
                <h3>Privacy policy</h3>
                <p>
                  We collect personally identifiable information from the users
                  of the Site. Please refer to our{' '}
                  <a href="/privacy-policy/">privacy policy</a> for information
                  on how we treat the information we gather about you.
                </p>
                <h3>Content</h3>
                <p>
                  We, or the parties providing materials through the Site,
                  retain all copyright and other proprietary rights contained in
                  the original materials or any copies of the materials
                  available through the Site. You may not modify the materials
                  on the Site in any way or reproduce or publicly display,
                  perform, or distribute or otherwise use them for any public or
                  commercial purpose. The materials on the Site are copyrighted
                  and any unauthorized use of any materials from the Site may
                  violate copyright, trademark, and other laws. We make no claim
                  of ownership of materials you submit to us using the Site
                  (your “Content”), but in submitting Content to us with the
                  Site you grant us the non-exclusive worldwide perpetual
                  irrevocable right to use, distribute, reproduce, modify,
                  adapt, publicly perform and publicly display such Content on
                  or using the Site, and you represent to us that you have the
                  right to do so and agree to indemnify and defend us against
                  any claim to the contrary.
                </p>
                <h3>Disclaimers</h3>
                <p>
                  THE MATERIALS ON THIS SITE ARE PROVIDED “AS IS” WITHOUT
                  WARRANTIES OF ANY KIND INCLUDING WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
                  NON-INFRINGEMENT OF INTELLECTUAL PROPERTY. Our obligations
                  with respect to our products and services are governed solely
                  by the agreements under which they are provided and nothing on
                  this Site should be construed to alter such agreements. We
                  further do not warrant the accuracy and completeness of the
                  materials on this Site. We may make changes to the materials
                  on this Site, or to the products and prices described in them,
                  at any time without notice. The materials on this Site may be
                  out of date, and we make no commitment to update the materials
                  and services at this Site. Information published at this Site
                  may refer to products, programs or services that are not
                  available in your country. Consult your local business contact
                  for information regarding the products, programs and services
                  that may be available to you. Applicable law may not allow the
                  exclusion of implied warranties, so this exclusion may not
                  apply to you.
                </p>
                <h3>Limitation of liability</h3>
                <p>
                  IN NO EVENT WILL WE, OUR SUPPLIERS, OR OTHER THIRD PARTIES
                  MENTIONED ON THIS SITE BE LIABLE FOR ANY DAMAGES WHATSOEVER
                  (INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOST
                  PROFITS, LOST DATA OR BUSINESS INTERRUPTION) ARISING OUT OF
                  THE USE, INABILITY TO USE, OR THE RESULTS OF USE OF THIS SITE,
                  ANY WEBSITES LINKED TO THIS SITE, OR THE MATERIALS OR
                  INFORMATION OR SERVICES CONTAINED ON ANY OR ALL SUCH SITES,
                  WHETHER BASED ON WARRANTY, CONTRACT, TORT OR ANY OTHER LEGAL
                  THEORY AND WHETHER OR NOT WE HAVE BEEN ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES. IF YOUR USE OF THE MATERIALS,
                  INFORMATION OR SERVICES FROM THIS SITE RESULTS IN THE NEED FOR
                  SERVICING, REPAIR OR CORRECTION OF EQUIPMENT OR DATA, YOU
                  ASSUME ALL COSTS THEREOF. APPLICABLE LAW MAY NOT ALLOW THE
                  EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL
                  DAMAGES, SO THESE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO
                  YOU.
                </p>
                <h3>Links to third-party websites</h3>
                <p>
                  Links on this Site to third-party websites are provided solely
                  as a convenience to you. If you use these links, you will
                  leave this Site. We have not reviewed all of these third-party
                  sites and do not control and are not responsible for any of
                  these sites or their content. Thus, we do not endorse or make
                  any representations about them, or any information, software
                  or other products or materials found there, or any results
                  that may be obtained from using them. If you decide to access
                  any of the third-party websites linked to this Site, you do
                  this entirely at your own risk. We encourage you to be aware
                  when you leave our site and to read the privacy statements of
                  each and every web site that collects personally identifiable
                  information. The Privacy Policy contained in these Terms
                  applies only to information collected by this Site.
                </p>
                <h3>General</h3>
                <p>
                  We administer this Site from our offices in the Commonwealth
                  of Massachusetts, USA. We make no representation that
                  materials or services at this Site are appropriate or
                  available for use outside the United States, and access to
                  them from territories where their contents are illegal is
                  prohibited. You may not use or export or re-export the
                  materials or services from this Site or any copy or adaptation
                  in violation of any applicable laws or regulations including
                  without limitation U.S. export laws and regulations. If you
                  choose to access this Site from outside the United States, you
                  do so on your own initiative and are responsible for
                  compliance with applicable local laws. These Terms will be
                  governed by and construed in accordance with the laws of the
                  Commonwealth of Massachusetts, without giving effect to any
                  principles of conflicts of laws.
                </p>
                <h3>Changes in these terms</h3>
                <p>
                  We may revise these Terms at any time by updating this
                  posting. You should visit this page from time to time to
                  review the then-current Terms because they are binding on you.
                  Certain provisions of these Terms may be superseded by
                  expressly designated legal notices or terms located on
                  particular pages on this Site.
                </p>
                <h3>Procedure for making claims of copyright infringement</h3>
                <p>
                  In accordance with the Digital Millennium Copyright Act (17
                  USC § 512), we are registered with the U.S. Copyright Office
                  as a Service Provider. Any notifications of claimed copyright
                  infringement must be sent to our Designated Agent. The
                  Designated Agent is our Legal Department,{' '}
                  <a href="tel:7814510150">(781) 451-0150</a>. Notification
                  should be mailed to 301 Edgewater Place, Suite 405, Wakefield,
                  MA 01880 or submitted via our{' '}
                  <a href="https://curaleaf.com/contact-us/">contact form</a>.
                </p>
                <h3>Contacting Us</h3>
                <p>
                  If you have any questions about these Terms, our privacy
                  practices, or this Site you may contact us:
                </p>
                <p>
                  by submitting our at{' '}
                  <a href="https://curaleaf.com/contact-us/">contact form</a>,
                </p>
                <p>
                  by calling us at <a href="tel:7814510150">(781) 451-0150</a>,
                </p>
                <p>or by mail:</p>
                <p>
                  301 Edgewater Place
                  <br />
                  Suite 405
                  <br />
                  Wakefield, MA 01880
                </p>
                <h3>Copyrights and Trademarks</h3>
                <p>
                  The contents of the Website are © 2018 Select, Inc. Trademarks
                  or registered trademarks of Select are in the United States
                  and other countries. All other product names, service marks
                  and trademarks mentioned herein are trademarks of their
                  respective owners.
                </p>
                <h3>
                  ARE YOU FAMILIAR WITH ADULT-USE CANNABIS REGULATIONS IN MA?
                </h3>
                <p>
                  Please Consume Responsibly This product has not been analyzed
                  or approved by the Food and Drug Administration (FDA). There
                  is limited information on the side effects of using this
                  product, and there may be associated health risks. Marijuana
                  use during pregnancy and breast-feeding may pose potential
                  harms. It is against the law to drive or operate machinery
                  when under the influence of this product. KEEP THIS PRODUCT
                  AWAY FROM CHILDREN. There may be health risks associated with
                  consumption of this product. Marijuana can impair
                  concentration, coordination, and judgment. The impairment
                  effects of Edible Marijuana Products may be delayed by two
                  hours or more. In case of accidental ingestion, contact poison
                  control hotline 1-800-222-1222 or 9-1-1. This product may be
                  illegal outside of MA.
                </p>
              </div>
            </section>
          </div>
        </article>
        <PrivacyPolicyView />
      </>
    );
  }
}

export default FindUsView;
