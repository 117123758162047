import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import TOSView from '../components/TermsOfServiceView';

import '../styles/global.scss';

const NotFoundPage = () => (
  <Layout view="find-us">
    <Seo title="Terms of Service" />
    <TOSView />
  </Layout>
);

export default NotFoundPage;
